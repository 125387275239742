import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Video } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This is not a very Trailguide specific how to. Nevertheless IOS
manages to hide its functions so well, that it often causes problems
for iPhone users using Trailguide. Here you can find out how you can
activate the location services and see your own location in Trailguide
to find and navigate on the best trails.`}</p>
    <Video src="aJcBQcQKWJA" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      